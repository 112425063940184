export const SET_PARKING_FETCH_REQUEST = 'SET_PARKING_FETCH_REQUEST'
export const PARKING_FETCH_SUCCESS = 'PARKING_FETCH_SUCCESS'
export const PARKING_FETCH_FAILURE = 'PARKING_FETCH_FAILURE'

export const ACTIVE_SESSION_FETCH_FAILURE = 'ACTIVE_SESSION_FETCH_FAILURE'
export const ACTIVE_SESSION_FETCH_SUCCESS = 'ACTIVE_SESSION_FETCH_SUCCESS'
export const ACTIVE_SESSION_FETCH = 'ACTIVE_SESSION_FETCH'

export const CONFIRM_VEHICLE = 'CONFIRM_VEHICLE'
export const CONFIRM_VEHICLE_SUCCESS = 'CONFIRM_VEHICLE_SUCCESS'
export const CONFIRM_VEHICLE_FAILURE = 'CONFIRM_VEHICLE_FAILURE'


export const FETCH_SESSION_IMAGE = 'FETCH_SESSION_IMAGE'
export const FETCH_SESSION_IMAGE_SUCCESS = 'FETCH_SESSION_IMAGE_SUCCESS'
export const FETCH_SESSION_IMAGE_FAILURE = 'FETCH_SESSION_IMAGE_FAILURE'

export const SET_FETCH_SESSION_DETAILS = 'SET_FETCH_SESSION_DETAILS'
export const FETCH_SESSION_DETAILS_FAILURE = 'FETCH_SESSION_DETAILS_FAILURE'
export const FETCH_SESSION_DETAILS_SUCCESS = 'FETCH_SESSION_DETAILS_SUCCESS'

export const SESSION_CONVERSION = 'SESSION_CONVERSION'
export const SESSION_CONVERSION_FAILURE = 'SESSION_CONVERSION_FAILURE'
export const SESSION_CONVERSION_SUCCESS = 'SESSION_CONVERSION_SUCCESS'

export const POST_SESSION_CONVERSION = 'POST_SESSION_CONVERSION'
export const POST_SESSION_CONVERSION_FAILURE = 'POST_SESSION_CONVERSION_FAILURE'
export const POST_SESSION_CONVERSION_SUCCESS = 'POST_SESSION_CONVERSION_SUCCESS'

export const SET_CONSUMER_USER_PAYMENT_ID_SUCCESS = 'SET_CONSUMER_USER_PAYMENT_ID_SUCCESS'
export const SET_PARKING_DURATION_SUCCESS = 'SET_PARKING_DURATION_SUCCESS'
export const SET_CHECKIN_TIME_SUCCESS = 'SET_CHECKIN_TIME_SUCCESS'
export const SET_OLD_SESSION_SUCCESS = 'SET_OLD_SESSION_SUCCESS'


export const SET_EMAIL_UPDATE = 'SET_EMAIL_UPDATE'
export const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS'
export const EMAIL_UPDATE_FAILURE = 'EMAIL_UPDATE_FAILURE'



