import {  
	PARKING_FETCH_SUCCESS, 
	ACTIVE_SESSION_FETCH_SUCCESS ,
	ACTIVE_SESSION_FETCH ,
	CONFIRM_VEHICLE_SUCCESS,
	FETCH_SESSION_IMAGE_SUCCESS,
	FETCH_SESSION_DETAILS_SUCCESS,
	SESSION_CONVERSION_SUCCESS,
	SET_CONSUMER_USER_PAYMENT_ID_SUCCESS,
	SET_PARKING_DURATION_SUCCESS,
	SET_CHECKIN_TIME_SUCCESS,
	EMAIL_UPDATE_SUCCESS,
	EMAIL_UPDATE_FAILURE,
	SET_OLD_SESSION_SUCCESS
} from '@redux/types/payGTypes'
import { combineObject } from '@redux/helpers'

export const initialState = {
	parkingDetails:{},
	activeSession : {},
	userDetails : {},
	sessionDetails : {},
	sessionConversionData : {},
	duration : '',
	checkInTime : '',
	email : '',
	errorMessage :'',
	consumerUserPaymentId : '',
	vehicleNumber : '',
	imageUrl:'',
	oldSessionDetails : {}

}

const payGReducer = (state = initialState, action) => {
	switch (action.type) {
	
		case PARKING_FETCH_SUCCESS:
			return combineObject(state, { parkingDetails:action.payload , oldSessionDetails : {} , email : '' })

		
		case ACTIVE_SESSION_FETCH_SUCCESS:
			return combineObject(state, {  activeSession:action.payload})
	
		case ACTIVE_SESSION_FETCH:
			return combineObject(state, { vehicleNumber : action.payload})

		case CONFIRM_VEHICLE_SUCCESS:
			return combineObject(state, {  userDetails : action.payload })


		case FETCH_SESSION_IMAGE_SUCCESS:
			return combineObject(state, { imageUrl : action.payload })
		
		
		case FETCH_SESSION_DETAILS_SUCCESS:
			return combineObject(state, {  sessionDetails : action.payload})

		
		case SESSION_CONVERSION_SUCCESS:
			return combineObject(state, {  sessionConversionData : action.payload })


		case SET_CONSUMER_USER_PAYMENT_ID_SUCCESS:
			return combineObject(state, { consumerUserPaymentId: action.payload })

		case SET_PARKING_DURATION_SUCCESS:
			return combineObject(state, { duration: action.payload })
		
		case SET_CHECKIN_TIME_SUCCESS:
			return combineObject(state, { checkInTime: action.payload })

		case EMAIL_UPDATE_SUCCESS:
			return combineObject(state, { email: action.payload })
			
		case EMAIL_UPDATE_FAILURE:
			return combineObject(state, { errorMessage: action.payload })

		case SET_OLD_SESSION_SUCCESS:
			return combineObject(state, { oldSessionDetails: action.payload })

		default:
			return state
	}
}

export default payGReducer;