import { SET_USER_ACTION_REQUEST, SET_USER_ACTION_FAILURE, SET_USER_DATA, CLEAR_USER_DATA } from '@redux/types/userType'
import { combineObject } from '@redux/helpers'

export const userInitState = {
	loading: false,
	user: {},
	error: '',
}

const userReducer = (state = userInitState, action) => {
	switch (action.type) {
		case SET_USER_ACTION_REQUEST:
			return combineObject(state, { loading: true })

		case SET_USER_ACTION_FAILURE:
			return combineObject(state, { loading: false, error: action.payload })

		case SET_USER_DATA:
			return combineObject(state, { loading: false, user: action.payload })

		case CLEAR_USER_DATA:
			return combineObject(state, { loading: false, user: {} })

		default:
			return state
	}
}

export default userReducer
