import {
	FETCH_MY_PERMIT_LIST_REQUEST,
	FETCH_MY_PERMIT_LIST_SUCCESS,
	FETCH_MY_PERMIT_LIST_FAILURE,
	UPDATE_MY_PERMIT_AUTO_RENEW,
	SET_VIEW_PERMIT_FAILURE,
	SET_RETRY_PAYMENT_REQUEST,
	SET_RETRY_PAYMENT_FAILURE,
	SET_RETRY_PAYMENT_SUCCESS,
	DELETE_MY_PERMIT_SUCCESS,
	DELETE_MY_PERMIT_REQUEST,
	DELETE_MY_PERMIT_FAILURE,
	VIEW_RECEIPT_REQUEST,
	SET_VIEW_PERMIT_SUCCESS,
} from '@redux/types/myPermitsTypes'
import { combineObject } from '@redux/helpers'

export const initialState = {
	loading: false,
	permitList:[],
	error: false,
	viewReceiptLoading: false,
	paymentLoading: false,
	deleteLoading: false,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_MY_PERMIT_LIST_REQUEST:
			return combineObject(state, { loading: true })

		case FETCH_MY_PERMIT_LIST_SUCCESS:
			return combineObject(state, { permitList: action.payload?.list, totalCount:  action.payload?.totalCount, loading: false })

		case FETCH_MY_PERMIT_LIST_FAILURE:
			return combineObject(state, { error: true, loading: false })
		case UPDATE_MY_PERMIT_AUTO_RENEW:
			const { id, isAutoRenewal } = action.payload
			state.permitList.find(item => item.id === id).isAutoRenewal = isAutoRenewal
			return state
		case VIEW_RECEIPT_REQUEST:
			return combineObject(state, { viewReceiptLoading: true })
		case SET_VIEW_PERMIT_FAILURE:
			return combineObject(state, { error: action.payload, viewReceiptLoading: false })
		case SET_VIEW_PERMIT_SUCCESS:
			return combineObject(state, { viewReceiptLoading: false })
		case SET_RETRY_PAYMENT_REQUEST:
			return combineObject(state, { paymentLoading: true })
		case SET_RETRY_PAYMENT_FAILURE:
			return combineObject(state, { paymentLoading: false })
		case SET_RETRY_PAYMENT_SUCCESS: {
			state.permitList.find(item => item.id === action.payload.id).paymentStatus = 'PAID'
			return combineObject(state, { paymentLoading: false })
		}
		case DELETE_MY_PERMIT_REQUEST:
			return combineObject(state, { deleteLoading: true })
		case DELETE_MY_PERMIT_SUCCESS:
			const permitListUpdated = state.permitList.filter(permit => permit.id !== action.payload.id)
			return combineObject(state, { permitList: permitListUpdated, deleteLoading: false })
		case DELETE_MY_PERMIT_FAILURE:
			return combineObject(state, { error: action.payload })
		default:
			return state
	}
}

export default reducer
