import config from './tenant.configs.json'

const { REACT_APP_tenant: tenant, REACT_APP_env: env, REACT_APP_HMAC_SALT: salt } = process.env
const tenant_config = config[tenant]

const cfg = {
	...tenant_config,
	env,
	apiBaseUrl: tenant_config.apiBaseUrl[env],
	salt,
}

// const validEnvs = ['staging', 'production', 'qa', 'dev']

// if (!validEnvs.includes(env)) {
// 	throw new Error(`Invalid config, allowed values for env : ${validEnvs.join(', ')}`)
// }

export default cfg
