import endpoints from 'services/request/endpoints'
import  { RequestBuilder, intercept } from 'utils/requestHelpers'
import CookieStore from 'services/CookieService'
import keys from 'services/keys'
import CryptoJS from 'crypto-js'
import uuid from 'uuid-random'
import appConfig from 'appConfig'

const { REACT_APP_tenant: slug, REACT_APP_HMAC_SALT: salt } = process.env

export const { apiBaseUrl, hmacUsername } = appConfig

const HMACHeaders = (method, endpoint) => {
	const date = new Date().toGMTString('EEE, dd MMM YYYY HH:mm:ss zzz')
	const signature = `x-date: ${date}\n${method} ${endpoint} HTTP/1.1\nx_gmp_tenant: ${slug}`
	const hash = CryptoJS.HmacSHA256(signature, salt)
	const h = CryptoJS.enc.Base64.stringify(hash).toString()

	return {
		'content-type': 'application/json',
		'x-date': date,
		X_DEVICE_ID: 'Web',
		X_GMP_TENANT: slug,
		Authorization: `hmac username="${hmacUsername}", algorithm="hmac-sha256", headers="x-date request-line x_gmp_tenant", signature="${h}"`,
		X_GMP_CORRELATION_ID: uuid(),
	}
}

export const HMACRequest = new RequestBuilder(apiBaseUrl, { headers: HMACHeaders }, intercept)

export const fetchMasterConfig = async() => {
    const entityId = window.location.origin
		try{
			const [error, response] = await HMACRequest.get(endpoints.MASTER_CONFIG, {attributeType:'WEB_CONFIG', entityType : 'URL', entityId : entityId})
			if(error){
				throw Error(error)
			}
			const web_config = response[0].attributeData
            //store the config in cookies
            CookieStore.set(keys.Config, web_config);
			return web_config
		}
		catch(e){
			console.error(e)
		}
}