import {
	SET_PARKING_SEARCH_PARAMS,
	FETCH_PARKING_LIST_REQUEST,
	FETCH_PARKING_LIST_SUCCESS,
	FETCH_PARKING_LIST_FAILURE,
	SET_PARKING_LIST_PAGE_NUMBER,
	CLEAR_PARKING_LIST,
	RETRY_FETCH_PARKING_LIST,
	FETCH_PARKING_DETAILS_REQUEST,
	SAVE_PARKING_DETAILS,
	SAVE_PARKING_IMAGES,
	INSERT_PARKING_DISPLAY_IMAGES,
} from '@redux/types/parkingTypes'
import { combineObject } from '@redux/helpers'

const initialState = {
	loading: false,
	parkingSearchParams: {
		name: '',
		coordinates: {
			lat: '',
			lng: '',
		},
	},
	parkingList: [],
	parkingDetails: {},
	parkingImages: {},
	total: 0,
	count: 0,
	pageNumber: 1,
	error: false,
	retryCount: 0,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PARKING_SEARCH_PARAMS:
			return combineObject(state, { parkingSearchParams: action.payload, parkingList: [], total: 0, count: 0 })

		case FETCH_PARKING_LIST_REQUEST:
			return combineObject(state, { loading: true })

		case FETCH_PARKING_LIST_SUCCESS:
			const { parkingList, total } = action.payload
			return combineObject(state, { parkingList, total, count : parkingList?.length, error: false, loading: false })

		case FETCH_PARKING_LIST_FAILURE:
			return combineObject(state, { parkingList: [], total: 0, count: 0, error: true, loading: false })

		case SET_PARKING_LIST_PAGE_NUMBER:
			return combineObject(state, { pageNumber: action.payload })

		case CLEAR_PARKING_LIST:
			return combineObject(initialState)

		case RETRY_FETCH_PARKING_LIST:
			return combineObject(state, { retryCount: state.retryCount + 1 })

		case INSERT_PARKING_DISPLAY_IMAGES:
			const list = state.parkingList
			list.forEach((parking, index) => {
				if (parking.id === action.payload.id) list[index].displayImage = action.payload.image
			})
			return combineObject(state, { parkingList: list })

		case FETCH_PARKING_DETAILS_REQUEST:
			return combineObject(state, {
				loadingParkingDetails: true,
				parkingDetails: { ...state.parkingDetails, [action.payload]: { error: false, loading: true } },
				parkingImages: { ...state.parkingImages, [action.payload]: [] },
			})

		case SAVE_PARKING_DETAILS:
			const parkingDetails = state.parkingDetails
			parkingDetails[action.payload.id] = { loading: false, ...action.payload }
			return combineObject(state, { loadingParkingDetails: false, parkingDetails })

		case SAVE_PARKING_IMAGES:
			return combineObject(state, { parkingImages: { ...state.parkingImages, [action.payload.id]: action.payload.imageResponses } })

		default:
			return state
	}
}

export default reducer
