import React from 'react'
import Notification from 'rc-notification'

import LoadingOutlined from 'icons/Cached'
import ExclamationCircleFilled from 'icons/Error'
import CloseCircleFilled from 'icons/Cancel'
import CheckCircleFilled from 'icons/CheckCircle'
import InfoCircleFilled from 'icons/Info'

import './style/message.css'

let defaultDuration = 3
let defaultTop
let messageInstance
let key = 1
let prefixCls = 'ant-message'
let transitionName = 'move-up'
let getContainer
let maxCount
let rtl = false
function getMessageInstance(callback) {
	if (messageInstance) {
		callback(messageInstance)
		return
	}
	Notification.newInstance(
		{
			prefixCls,
			transitionName,
			style: { top: defaultTop },
			getContainer,
			maxCount,
		},
		instance => {
			if (messageInstance) {
				callback(messageInstance)
				return
			}
			messageInstance = instance
			callback(instance)
		},
	)
}
const iconMap = {
	info: InfoCircleFilled,
	success: CheckCircleFilled,
	error: CloseCircleFilled,
	warning: ExclamationCircleFilled,
	loading: LoadingOutlined,
}
function notice(args) {
	const duration = args.duration !== undefined ? args.duration : defaultDuration
	const IconComponent = iconMap[args.type]
	let messageClass = `${prefixCls}-custom-content`
	if (args.type) messageClass = messageClass + ` ${prefixCls}-${args.type}`
	if (rtl) messageClass = messageClass + ` ${prefixCls}-rtl`

	const target = args.key || key++
	const closePromise = new Promise(resolve => {
		const callback = () => {
			if (typeof args.onClose === 'function') {
				args.onClose()
			}
			return resolve(true)
		}
		getMessageInstance(instance => {
			instance.notice({
				key: target,
				duration,
				style: {},
				content: (
					<div className={messageClass} style={{ display: 'flex', alignItems: 'center' }}>
						{args.icon || (IconComponent && <IconComponent style={{ fontSize: '16px', marginRight: '4px' }} className='anticon' />)}
						<span>{args.content}</span>
					</div>
				),
				onClose: callback,
			})
		})
	})
	const result = () => {
		if (messageInstance) {
			messageInstance.removeNotice(target)
		}
	}
	result.then = (filled, rejected) => closePromise.then(filled, rejected)
	result.promise = closePromise
	return result
}
function isArgsProps(content) {
	return Object.prototype.toString.call(content) === '[object Object]' && !!content.content
}

const make = type => (content, duration, onClose) => {
	if (isArgsProps(content)) {
		return notice(Object.assign(Object.assign({}, content), { type }))
	}
	if (typeof duration === 'function') {
		onClose = duration
		duration = undefined
	}
	return notice({ content, duration, type, onClose })
}

const api = {
	open: notice,
	config(options) {
		if (options.top !== undefined) {
			defaultTop = options.top
			messageInstance = null // delete messageInstance for new defaultTop
		}
		if (options.duration !== undefined) {
			defaultDuration = options.duration
		}
		if (options.prefixCls !== undefined) {
			prefixCls = options.prefixCls
		}
		if (options.getContainer !== undefined) {
			getContainer = options.getContainer
		}
		if (options.transitionName !== undefined) {
			transitionName = options.transitionName
			messageInstance = null // delete messageInstance for new transitionName
		}
		if (options.maxCount !== undefined) {
			maxCount = options.maxCount
			messageInstance = null
		}
		if (options.rtl !== undefined) {
			rtl = options.rtl
		}
	},
	destroy() {
		if (messageInstance) {
			messageInstance.destroy()
			messageInstance = null
		}
	},
	success: make('success'),
	info: make('info'),
	warning: make('warning'),
	error: make('error'),
	loading: make('loading'),
}

api.warn = api.warning
const message = api
export default message
