export const queryString = params =>
	Object.keys(params)
		.map(key => {
			const value = Array.isArray(params[key]) ? params[key].join(',') : params[key]
			return key + '=' + value
			// return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		})
		.join('&')

export function camelize(str) {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
			return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
		})
		.replace(/\s+/g, '')
}

export function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w-]+/g, '') // Remove all non-word chars
		.replace(/--+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text
}

export const capitalize = string => {
	if (!string) {
		return ''
	}

	const hasSpaces = string.includes(' ')
	string = string.toLowerCase()

	const capitalizeWord = word => word.charAt(0).toUpperCase() + word.slice(1)

	return hasSpaces ? string.split(' ').map(capitalizeWord).join(' ') : capitalizeWord(string)
}

export const validateTIN = number => {
	const TinRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/
	return TinRegex.test(number)
}

export const getDefault = arr => (arr && !!arr.length && (arr.find(({ isDefault }) => !!isDefault) || arr[0])) || {}

export const delayedPromise = async (promise, delay = 1000) => {
	const timerPromise = new Promise(resolve => setTimeout(resolve, delay))
	const response = await Promise.all([timerPromise, promise]).catch(err => {
		throw new Error(err)
	})
	return response[1]
}

export const intersection = (arr1, arr2) => arr1.filter(value => -1 !== arr2.indexOf(value))

// To chunk array into arrays of given size;
export const chunk = (array, size) => {
	const chunked_arr = []
	for (let i = 0; i < array.length; i++) {
		const last = chunked_arr[chunked_arr.length - 1]
		if (!last || last.length === size) {
			chunked_arr.push([array[i]])
		} else {
			last.push(array[i])
		}
	}
	return chunked_arr
}

export const range = (start, end) => {
	const array = []
	for (let i = start; i < end; i++) array.push(i)
	return array
}

export const removeProperties = (obj, props) => {
	const duplicateObject = Object.assign({}, obj)
	for (const prop of props) {
		prop in duplicateObject && delete duplicateObject[prop]
	}
	return duplicateObject
}

export const validateMobileNumber = (rule, number, callback) => {
	const pattern = new RegExp(/^[1-9]{1}[0-9]{9}$/)
	if (pattern.test(number) || !number) callback()
	else callback('Invalid Mobile Number')
}

export const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array)
	}
}

export const debounce = (fn, timeout = 1000) => {
	let timer;
	return (...args) => {
	  clearTimeout(timer)
	  timer = setTimeout(() => { fn.apply(this, args) }, timeout);
	}
  }

export const encrypt = (input) =>  {
	return btoa(JSON.stringify(input));
}


export const decrypt = (input)  => {
return JSON.parse(atob(input));
}

export const getValueFromLocalStorage = key => {
	return localStorage.getItem(`${key}`) ? JSON.parse(localStorage.getItem(`${key}`)) : null
}

export const setValueInLocalStorage = (key, value) => {
	localStorage.setItem(`${key}`, JSON.stringify(value))
	return
}
