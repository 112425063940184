import { queryString } from 'utils/helpers'
import CookieStore from 'services/CookieService'

export const RequestBuilder = class RequestBuilder {
	constructor(BaseUrl, options, interceptor) {
		this.API_URL = BaseUrl ?? ''
		this.options = options ?? {}
		this.interceptor = interceptor ?? (request => request.json())
	}
	// function makes an api call, any logs for the apis logs can be attached here
	makeRequest = async (endpoint, options) => {
		const [error, response] = await fetch(endpoint, options)
			.then(async response => [null, await this.interceptor(response)])
			.catch(error => [error, null])
		return [error, response]
	}

	get = async (url, params, options = {}) => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`,
			method = 'GET'
		return this.makeRequest(endpoint, { method, ...options, headers: this.options.headers(method, url) })
	}

	post = async (url, data, params, options = {} , contentType  = "application/json") => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`,
			method = 'POST'

		return this.makeRequest(endpoint, { method, headers: this.options.headers(method, url , contentType), ...(data && { body: JSON.stringify(data), ...options }) })
	}
	postDoc = async (url, data, params) => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`,
			method = 'POST'

		return this.makeRequest(endpoint, { method, headers: this.options.headers(method, url), body: data })
	}

	put = async (url, data, params, options = {}) => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`,
			method = 'PUT'

		return this.makeRequest(endpoint, { method, headers: this.options.headers(method, url), ...(data && { body: JSON.stringify(data), ...options }) })
	}

	delete = async (url, data, params, options = {}) => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`,
			method = 'DELETE'

		return this.makeRequest(endpoint, { method, headers: this.options.headers(method, url), ...(data && { body: JSON.stringify(data), ...options }) })
	}

	graphql = async (url, data, params, options = {}) => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`,
			method = 'POST'

		return this.makeRequest(endpoint, { method, headers: this.options.headers(method, url), ...(data && { body: JSON.stringify(data), ...options }) })
	}

	make = async (url, data, params, options = { method: 'GET' }) => {
		const endpoint = `${this.API_URL}${url}${params ? `?${queryString(params)}` : ''}`

		return this.makeRequest(endpoint, { headers: this.options.headers(options.method, url), ...(data && { body: JSON.stringify(data), ...options }) })
	}

	done = callback => callback()
}

export const intercept = async request => {
	const { status } = request
	switch (true) {
		case status === 419:
			CookieStore.logout()
			throw new Error((await request.json()).message)
		case status === 400:
			throw new Error((await request.json()).message)
		case status === 401:
			throw new Error((await request.json()).message)
		case status === 404:
			throw new Error((await request.json()).message || 'Not found')
		case status === 500:
			throw new Error('Something went wrong')
		case status === 204:
			return { status: 'OK' }
		default:
			return request.json()
	}
}