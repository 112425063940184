import { DO_USER_LOGIN, DO_USER_LOGOUT, SET_AUTH_REQUEST, SET_AUTH_FAILURE, DO_USER_SIGNUP } from '@redux/types/authTypes'
import { combineObject } from '@redux/helpers'

export const authInitState = {
	loading: false,
	authToken: undefined,
	loggedIn: false,
	error: '',
}

const authReducer = (state = authInitState, action) => {
	switch (action.type) {
		case SET_AUTH_REQUEST:
			return combineObject(state, { loading: true })

		case SET_AUTH_FAILURE:
			return combineObject(state, { loading: false, error: action.payload })

		case DO_USER_LOGIN:
			return combineObject(state, { loading: false, authToken: action.payload, loggedIn: true })

		case DO_USER_LOGOUT:
			return combineObject(state, { loading: false, authToken: undefined, loggedIn: false })

		case DO_USER_SIGNUP:
			return combineObject(state, { loading: false, authToken: action.payload, loggedIn: true })

		default:
			return state
	}
}

export default authReducer
