import React, { useState } from 'react'
import message from 'components/message'
import appConfig from 'appConfig'
import { ConfigContext } from 'src/contexts/reactContexts'
import { Suspense } from 'react'
import { CircularProgress } from '@material-ui/core'
import { fetchMasterConfig } from './appConfig/fetchMasterConfig'
// import Logo from './views/components/shared/Logo'

const Views = React.lazy(() => import('./views'))

const App = () => {
	const [masterConfig, setConfig] = useState(null)
	window.addEventListener('beforeinstallprompt', e => {
		e.preventDefault()
		window.installEvent = e
	})

	React.useEffect(() => {
		const link = document.createElement('link')
		link.setAttribute('rel', 'preconnect')
		link.setAttribute('href', appConfig.apiBaseUrl)
		document.head.appendChild(link)
		// fetch the master config
		fetchMasterConfig().then((res) => {
			setConfig(res)
		})
	}, [])

	message.config({ maxCount: 3 })
	if(masterConfig === null){
		return null
	}
	return (
		<div data-test='component-app'>
			<ConfigContext.Provider value = { {masterConfig} }>
			<Suspense fallback={
			<div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
			}>
			<Views />
			</Suspense>
			</ConfigContext.Provider>
		</div>
	)
}

export default App
