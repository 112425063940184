const authentication = '/authentication/v1'
const suffix = '/'
export default {
	LOGIN: authentication + '/consumer/login' + suffix,
	FORGOT_PASSWORD: authentication + '/consumer/forgotPassword' + suffix,
	CHANGE_PASSWORD: authentication + '/consumer/changePassword' + suffix,
	SIGNUP: authentication + '/consumer/signUp' + suffix,
	VEHICLE: authentication + '/consumer/vehicle' + suffix,
	CONSUMER: authentication + '/consumer' + suffix,
	CONSUMER_ADDRESS: authentication + '/consumer/address' + suffix,
	CONSUMER_TOKEN: authentication + '/payment/transaction/customerToken' + suffix,
	PAYMENT_NONCE: authentication + '/consumer/payment' + suffix,
	PARKING_BY_ID: authentication + '/parking' + suffix,
	LOGOUT: authentication + '/consumer/logout' + suffix,
	NEARBY_PARKING_SEARCH: authentication + '/parking/nearby' + suffix,
	PARKING_IMAGES: authentication + '/parking/parkingImages' + suffix,
	GRAPHQL_CONSUMER: authentication + '/permit/graphql/consumer' + suffix,
	GRAPHQL_OPERATOR: authentication + '/permit/graphql/operator' + suffix,
	UPLOAD_DOCUMENTS: authentication + '/consumers/documents' + suffix,
	FETCH_PARKING: authentication + '/parking/getByCpmsKey' + suffix,
	FETCH_ACTIVE_SESSION: authentication + '/enable/payG/checkActiveSessionOrPartnerAuth' ,
	CONFIRM_VEHICLE: authentication + '/consumer/signUp' ,
	FETCH_TARRIF_DETAILS : authentication + '/tariff/calculate' ,
	FETCH_SESSION_IMAGE : authentication + '/enable/payG/sessionRequestImage',
	SESSION_CONVERSION : authentication + '/enable/payG/sessionConversion',
	POST_SESSION_CONVERSION : authentication + '/enable/payG/sessionConversion/postProcess',
	EMAIL_UPDATE : authentication + '/consumer',
	PAYMENT_REDIRECT_URL_FETCH : authentication + '/consumer/payment/redirectUrl',
	MASTER_CONFIG : authentication + '/company/companyAttribute'
}