export const FETCH_MY_PERMIT_LIST_REQUEST = 'FETCH_PERMIT_LIST_REQUEST'
export const FETCH_MY_PERMIT_LIST_SUCCESS = 'FETCH_MY_PERMIT_LIST_SUCCESS'
export const FETCH_MY_PERMIT_LIST_FAILURE = 'FETCH_MY_PERMIT_LIST_SUCCESS'
export const UPDATE_MY_PERMIT_AUTO_RENEW = 'UPDATE_MY_PERMIT_AUTO_RENEW'
export const VIEW_RECEIPT_REQUEST = 'VIEW_RECEIPT_REQUEST'
export const SET_VIEW_PERMIT_SUCCESS = 'SET_VIEW_PERMIT_SUCCESS'
export const SET_VIEW_PERMIT_FAILURE = 'SET_VIEW_PERMIT_FAILURE'
export const SET_RETRY_PAYMENT_REQUEST = 'SET_RETRY_PAYMENT_REQUEST'
export const SET_RETRY_PAYMENT_FAILURE = 'SET_RETRY_PAYMENT_FAILURE'
export const SET_RETRY_PAYMENT_SUCCESS = 'SET_RETRY_PAYMENT_SUCCESS'
export const DELETE_MY_PERMIT_REQUEST = 'DELETE_MY_PERMIT_REQUEST'
export const DELETE_MY_PERMIT_SUCCESS = 'DELETE_MY_PERMIT_SUCCESS'
export const DELETE_MY_PERMIT_FAILURE = 'DELETE_MY_PERMIT_FAILURE'
