export const SET_PARKING_SEARCH_PARAMS = 'SET_PARKING_SEARCH_PARAMS'
export const FETCH_PARKING_LIST_REQUEST = 'FETCH_PARKING_LIST_REQUEST'
export const FETCH_PARKING_LIST_SUCCESS = 'FETCH_PARKING_LIST_SUCCESS'
export const FETCH_PARKING_LIST_FAILURE = 'FETCH_PARKING_LIST_FAILURE'
export const SET_PARKING_LIST_PAGE_NUMBER = 'SET_PARKING_LIST_PAGE_NUMBER'
export const CLEAR_PARKING_LIST = 'CLEAR_PARKING_LIST'
export const RETRY_FETCH_PARKING_LIST = 'RETRY_FETCH_PARKING_LIST'

export const INSERT_PARKING_DISPLAY_IMAGES = 'INSERT_PARKING_DISPLAY_IMAGES'
export const FETCH_PARKING_DETAILS_REQUEST = 'FETCH_PARKING_DETAILS_REQUEST'
export const SAVE_PARKING_DETAILS = 'SAVE_PARKING_DETAILS'
export const SAVE_PARKING_IMAGES = 'SAVE_PARKING_IMAGES'
