import { SET_CONSUMER_TOKEN_FAILURE, SET_REDIRECTION_ROUTE, SET_PAYMENT_NOUCE } from '@redux/types/paymentTypes'
import { combineObject } from '@redux/helpers'

export const initialState = {
	error: '',
	redirectionUrl: '',
	paymentGatewayUrl: '',
	paymentNouce: {}
}

const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CONSUMER_TOKEN_FAILURE:
			return combineObject(state, { error: action.error })
		case SET_REDIRECTION_ROUTE:
			return combineObject(state, { redirectionUrl: action.payload.currentRoute, paymentGatewayUrl: action.payload.redirectionUrl })
		case SET_PAYMENT_NOUCE:
			return combineObject(state, { paymentNouce: action.payload })
		default:
			return state
	}
}

export default paymentReducer
