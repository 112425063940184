import { combineReducers } from 'redux'
import parking from '@redux/reducers/parkingReducer'
import auth from '@redux/reducers/authReducer'
import user from '@redux/reducers/userReducer'
import vehicle from '@redux/reducers/vehicleReducer'
import myPermits from '@redux/reducers/myPermitsReducer'
import payGReducer from '@redux/reducers/payGReducer'
import paymentReducer from '@redux/reducers/paymentReducer'
const rootReducer = combineReducers({ parking, auth, user, vehicle, myPermits, payGReducer , paymentReducer})

export default rootReducer