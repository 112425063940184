import Cookie from 'universal-cookie'
import keys from './keys'

export const Cookies = new Cookie()

class CookiesService {
	get = name => {
		return Cookies.get(name)
	}
	set = (name, value, options = {}) => {
		Cookies.set(name, value, { path: '/', ...options })
	}

	logout = () => {
		Cookies.remove(keys.TOKEN, { path: '/' })
		Object.keys(this.all).forEach(key => Cookies.remove(key, { path: '/' }))
	}

	get all() {
		return Cookies.getAll()
	}

	get isLoggedIn() {
		return Boolean(Cookies.get(keys.TOKEN))
	}
}
const CookieStore = new CookiesService()
export default CookieStore
