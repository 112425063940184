import { SET_VEHICLE_REQUEST, SET_VEHICLE_FAILURE, SET_VEHICLE_ADDITION } from '@redux/types/vehicleTypes'
import { combineObject } from '@redux/helpers'

export const vehicleInitState = {
	loading: false,
	error: '',
	vehicles: [],
}

const vehicleReducer = (state = vehicleInitState, action) => {
	switch (action.type) {
		case SET_VEHICLE_REQUEST:
			return combineObject(state, { loading: true, vehicles: action.payload })
		case SET_VEHICLE_FAILURE:
			return combineObject(state, { loading: false, error: action.payload })
		case SET_VEHICLE_ADDITION:
			return combineObject(state, { loading: false })
		default:
			return state
	}
}

export default vehicleReducer
